import $ from 'jquery';
import 'slick-carousel';

$(document).ready(function() {

	$('.block_gallery__classic').slick({
		arrows: false,
		dots: false,
		infinite: true,
		speed: 300,
		slidesToShow: 5,
		slidesToScroll: 5,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 4
				}
			},
		{
			breakpoint: 600,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 2
			}
		},
		{
			breakpoint: 480,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2
				}
			}
		]
	});

	$('.slider-vertical').slick({
		dots: true,
		fade: true,
		//vertical: true,
		slidesToShow: 1,
		slidesToScroll: 1
		//verticalSwiping: true,
	});

	$('.slider-horizontal').slick({
		dots: true,
		fade: true,
		//vertical: true,
		slidesToShow: 1,
		slidesToScroll: 1
		//verticalSwiping: true,
	});

});